Lyte.Mixin.register("crux-aria-table-mixin", {
	phoneMouseOut : function(ele){
		if(ele.querySelector("a")){
			ele.querySelector("a").style.visibility = "hidden";
		}
     },
	 phoneMouseOver : function(ele){
		if(ele.querySelector("a")){
			ele.querySelector("a").style.visibility = "visible";
		}
	 },
    onFocus : function(){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var documentActiveElement = document.activeElement;
        var compThis = this;
        var table = compThis.lyteTable;
        var ariaActiveElem = compThis.querySelector('.cxAriaActive');

        /* remove cxAriaActive class from old aria active element */
        if(ariaActiveElem){
            $L('.cxAriaActive', compThis).removeClass('cxAriaActive');
        }

        /* add cxAriaActive class for new aria active element */
        if(documentActiveElement && !($L(documentActiveElement).hasClass('cxAriaActive'))){
            $L(documentActiveElement).addClass('cxAriaActive');
        }
        // var focusableCellsToTraverse = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp');
        // if((!table.contains(event.relatedTarget)) && focusableCellsToTraverse && (event.target === focusableCellsToTraverse[focusableCellsToTraverse.length-1])){
        //     table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp')[0].focus();
        // }
        
        /* refocus to the given focusable cell or displayColumn or first cell and scroll into 0,0 if active cell has class lyteTableFixed */
        var focusableCellsToTraverse = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp');
        var focusableCellsToTraverseLen = focusableCellsToTraverse.length;
        if((!table.contains(event.relatedTarget)) && focusableCellsToTraverse && (event.target === focusableCellsToTraverse[focusableCellsToTraverseLen-1])){
			var cxInitialFocusOnTable = focusableCellsToTraverse[0];
			if($L(cxInitialFocusOnTable).hasClass('lyteFixedColumn')){
				var scrollDiv = $L(cxInitialFocusOnTable).cxGetScrollParent();
				scrollDiv.scrollLeft = 0;
				scrollDiv.scrollTop = 0;
            }
            cxInitialFocusOnTable.focus();
        }

        /* add attribute listener if focusable element is inside cell */
		if(ariaActiveElem && $L(ariaActiveElem).attr('focusable') && !(ariaActiveElem.tagName.includes('TH') || ariaActiveElem.tagName.includes('TD')) && !$L(compThis).attr('listener')){
            compThis.addEventListener('keydown', mixinFuncCall.actionCompEvent);
            $L(compThis).attr('listener', true);
        }else if(ariaActiveElem && (ariaActiveElem.tagName.includes('TH') || ariaActiveElem.tagName.includes('TD')) && compThis.querySelector('.cxAriaActive [focusable]') && $L(compThis).attr('listener')){
            compThis.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
            $L(compThis).removeAttr('listener');
        }
    },
    onFocusOut : function(){
        var compThis = this;
        var ariaActiveElem = $L(compThis.querySelector('.cxAriaActive'));
        if(ariaActiveElem[0]){
            /* remove cxPrevAriaActive class from old previous aria active element */
            if(compThis.querySelector('.cxPrevAriaActive')){
                $L('.cxPrevAriaActive', compThis).removeClass('cxPrevAriaActive');
            }
            if(!(ariaActiveElem.hasClass('cxPrevAriaActive'))){
                ariaActiveElem.addClass('cxPrevAriaActive');
            }
            ariaActiveElem.removeClass('cxAriaActive');
        }
    },
    keyEvent : function(){
        /* getting datas for keyboard navigation */
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var targetTable = event.currentTarget;
        // var thisComp = this;
        // if(this.$node){
        //     thisComp = this.$node;
        // }
        if(!targetTable.tagName.includes('TABLE') && this.tagName.includes('TABLE')){
            targetTable = this;
        }
        var table = targetTable;
        if(targetTable.querySelector('lyte-table:not(.cxPropAriaSkip), lyte-expresstable:not(.cxPropAriaSkip), table:not(.cxPropAriaSkip)')){
            table = targetTable.querySelector('lyte-table:not(.cxPropAriaSkip), lyte-expresstable:not(.cxPropAriaSkip), table:not(.cxPropAriaSkip)');
        }
        var cruxTable = table.cruxTable;
        var lyteTable = table.lyteTable;
        var parentCruxComp = table.parentCruxComp;
        var parentWormHole = table.parentWormHole;
        var modalContainer = table.modalContainer;
        var tableCells = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp');

        /* Aria and keydown listener for "no result found" */
        if(parentCruxComp.tagName.includes('CRUX') && parentCruxComp.cxProp('noContentClass') && (parentCruxComp.querySelector('.'+parentCruxComp.cxProp('noContentClass')) || parentWormHole.querySelector('.'+parentCruxComp.cxProp('noContentClass')))){
            var noResult = parentCruxComp.querySelector('.'+parentCruxComp.cxProp('noContentClass'))?parentCruxComp.querySelector('.'+parentCruxComp.cxProp('noContentClass')):parentWormHole.querySelector('.'+parentCruxComp.cxProp('noContentClass'));
            if(noResult.textContent && noResult.textContent !== ''){
                noResult.tabIndex = 0;
                noResult.ariaLabel = noResult.textContent;

                if(!$L(noResult).attr('cxAriaTableKeyEvent')){
                    noResult.listener = mixinFuncCall.keyEvent.bind(table);
                    noResult.addEventListener('keydown', noResult.listener);
                    $L(noResult).attr('cxAriaTableKeyEvent', true);
                }

                if(!parentCruxComp.cxProp('noContentClass').includes('noResultAriaComp')){
                    var noContClass = parentCruxComp.cxProp('noContentClass');
                    noContClass += ' noResultAriaComp';
                    parentCruxComp.cxProp('noContentClass', noContClass)
                }
                if(!$L(noResult).attr('cxAriaTableFocus')){
                    noResult.addEventListener('focusin', mixinFuncCall.onFocus.bind(table));
                    noResult.addEventListener('focusout', mixinFuncCall.onFocusOut.bind(table));
                    $L(noResult).attr('cxAriaTableFocus', true);
                }
            }
        }

        /* call addLabel to set aria for newly rendered components */
        var tableCellsLen = tableCells.length;
        var index;
        for(index=0;index<tableCellsLen;index++){
            mixinFuncCall.addLabel.call(table, tableCells[index]);
        }

        /* callback after added aria */
        if(cruxTable && cruxTable.tagName.includes('CRUX') && cruxTable.getMethods('afterAriaAdd')){
            cruxTable.component.executeMethod('afterAriaAdd');
        }

		/* check for table have cxIsEditOpen add listener true while ajax edit opens */
		if(!table.attributes.listener && table.attributes.cxIsEditOpen){
			$L(table).attr('listener', true)
		}

        /* checking if listener attr is true to navigate through sub focusable components in table */
        if(table.attributes && !table.attributes.listener){
            if(table.querySelector('.cxAriaActive')){
                table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
            }else{
                table.addEventListener('keydown', mixinFuncCall.actionCompEvent);
                $L(table).attr('listener');
            }
        }else if(table.attributes && table.attributes.listener){
            if(table.querySelector('.cxAriaActive')){
                table.addEventListener('keydown', mixinFuncCall.actionCompEvent);
            }else{
                table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
                $L(table).removeAttr('listener');
            }
        }

        /* select all rows in table includes "no result found" context row if available */
        var parentOfActiveElem = parentCruxComp;
        if(parentWormHole !== lyteTable){
            parentOfActiveElem = parentWormHole
        }
        var ariaActiveElement = parentOfActiveElem.querySelector('.cxAriaActive');
        if(((table.querySelector('.cxAriaActive') || table.className.includes('cxAriaActive')) || (ariaActiveElement && ariaActiveElement.className.includes('noResultAriaComp'))) && (table.tagName === 'CRUX-TABLE-COMPONENT' || !table.querySelector('lyte-table, lyte-expresstable, table'))){
            var tableRow = table.querySelectorAll('lyte-tr, lyte-exptable-tr, tr, crux-lookupfilter-component');
            if(parentCruxComp && parentCruxComp.tagName.includes('CRUX')){
                if(parentWormHole && parentWormHole.querySelector('.noResultAriaComp')){
                    tableRow = parentWormHole.querySelectorAll('.noResultAriaComp, lyte-tr, lyte-exptable-tr, tr, crux-lookupfilter-component');
                }else if(parentCruxComp && parentCruxComp.querySelector('.noResultAriaComp')){
                    tableRow = parentCruxComp.querySelectorAll('.noResultAriaComp, lyte-tr, lyte-exptable-tr, tr, crux-lookupfilter-component');
                }
            }
            var tableRowInitialLen = tableRow.length;
            for(index=0;index<tableRowInitialLen;index++){
                if(tableRow[index].className.includes('noResultAriaComp')){
                    if(parentWormHole && parentWormHole.querySelector('.noResultAriaComp')){
                        tableRow = parentWormHole.querySelectorAll('.noResultAriaComp, lyte-tr:not(lyte-tr.cxUserLookupEmptyRow), lyte-exptable-tr:not(lyte-exptable-tr.cxUserLookupEmptyRow), tr:not(tr.cxUserLookupEmptyRow), crux-lookupfilter-component');
                    }else if(parentCruxComp && parentCruxComp.querySelector('.noResultAriaComp')){
                        tableRow = parentCruxComp.querySelectorAll('.noResultAriaComp, lyte-tr:not(lyte-tr.cxUserLookupEmptyRow), lyte-exptable-tr:not(lyte-exptable-tr.cxUserLookupEmptyRow), tr:not(tr.cxUserLookupEmptyRow), crux-lookupfilter-component');
                    }
                }
            }
            /* key navigation */
            var mev = new Event("mouseover");
            var mout = new Event("mouseout");
            var tableComp = $L(table);
            var preventTab = false;
            if(((!tableComp.attr('listener')) || (tableComp.attr('listener') === "false"))){ // && !tableComp.attr('cxIsEditOpen')
                if(event.key.includes('Arrow') && !document.querySelector('.lyteDropdownDown, .lyteDropdownUp, .lyteDropdownRight, .lyteDropdownLeft')){
                    var tableRowLen = tableRow.length;
                    for(var i=0;i<tableRowLen;i++){
                        var focusableCells = tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                        var temp = 0;
                        var t;
                        if(tableRow[i].className && tableRow[i].className.includes('noResultAriaComp') && tableRow[i].className.includes('cxAriaActive') && (event.key === 'ArrowUp' || event.key === 'ArrowDown')){
                            focusableCells = tableRow[i];
                            t = i;
                            while(focusableCells.className.includes('cxAriaActive')){
                                if(event.key === 'ArrowDown' && t === tableRowLen-1){
                                    t = 0
                                }else if(event.key === 'ArrowUp' && t === 0){
                                    t = tableRowLen-1;
                                }else if(event.key === 'ArrowDown'){
                                    t = t+1;
                                }else if(event.key === 'ArrowUp'){
                                    t = t-1;
                                }
                                var tempTableCells;
                                var tempTableCellsLen;
                                if(tableRow[t]){
                                    tempTableCells = tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                                    tempTableCellsLen = tempTableCells.length;
                                    if(tempTableCellsLen>0){
                                        tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[0].focus();
                                    }
                                }
                            }
                        }else{
                            var focusableCellsLen = focusableCells.length;
                            var tableAriaActiveElem = table.querySelector('.cxAriaActive'); // eslint-disable-line @zoho/webperf/no-multipleDOMLookup
                            for(var j=0;j<focusableCellsLen;j++){
                                if(tableAriaActiveElem !== focusableCells[j] && focusableCells[j].querySelector('.cxAriaActive')){
                                    focusableCells[j].focus();
                                }
                                if(tableAriaActiveElem === focusableCells[j]){
                                    var prevActElem = document.activeElement;
                                    t = (event.key === 'ArrowUp' || event.key === 'ArrowDown')?i:j;
                                    if(event.key === 'ArrowUp' || event.key === 'ArrowDown'){
                                        event.preventDefault();
                                        do{
                                            if(tableRow[t] && tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j]){
                                                tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j].dispatchEvent(mout);
                                            }
                                            tableRow[t].dispatchEvent(mout);
                                            if(event.key === 'ArrowUp'){
                                                t = (t === 0)?tableRowLen-1:t-1;
                                            }else if(event.key === 'ArrowDown'){
                                                t = (t === tableRowLen-1)?0:t+1;
                                            }
                                            tableRow[t].dispatchEvent(mev);
                                            if(tableRow[t] && tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j]){
                                                tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j].dispatchEvent(mev);
                                                tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j].focus();
                                            }else if(tableRow[t].className.includes('noResultAriaComp')){
                                                tableRow[t].dispatchEvent(mev);
                                                tableRow[t].focus();
                                            }
                                        }while(document.activeElement === prevActElem && (i !== t));
                                        temp = 1;
                                        break;
                                    }else if(event.key === 'ArrowLeft' || event.key === 'ArrowRight'){
                                        event.preventDefault();
                                        do{
                                            if(tableRow[i] && tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[t]){
                                                tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[t].dispatchEvent(mout);
                                            }
                                            if(event.key === 'ArrowLeft'){
                                                t = (t === 0)?focusableCellsLen-1:t-1;
                                            }else if(event.key === 'ArrowRight'){
                                                t = (t === focusableCellsLen-1)?0:t+1;
                                            }
                                            tableRow[i].dispatchEvent(mev);
                                            if(tableRow[i] && tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[t]){
                                                tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[t].dispatchEvent(mev);
                                                tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[t].focus();
                                            }
                                        }while(document.activeElement === prevActElem && (j !== t));
                                        temp = 1;
                                        break;
                                    }
                                }
                            }
                        }
                        if(temp === 1){
                            break;
                        }
                    }
                    event.stopImmediatePropagation();
                }else if(event.key === ' ' || event.key === 'Enter'){
                    event.preventDefault();
                    var activeCell = document.activeElement;
                    while(!(activeCell.tagName.includes('TH') || activeCell.tagName.includes('TD') || activeCell.tagName === 'BODY')){
                        activeCell = activeCell.parentElement;
                    }
                    var activeRow = activeCell;
                    while(!(activeRow.tagName.includes('TR') || activeRow.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' || activeRow.tagName === 'BODY')){
                        activeRow = activeRow.parentElement;
                    }
                    activeRow.dispatchEvent(mev);
                    if(event.target === activeCell){
                        mixinFuncCall.clickOnComp(activeCell);
                        var actCellFocusable;
                        if(activeCell){
                            actCellFocusable = activeCell.querySelector('[focusable]:not(.cxSkipFocusable)');
                        }
                        if(actCellFocusable){
                            if(actCellFocusable.type === 'checkbox'){
                                var comp = actCellFocusable;
                                $L(activeRow).on('click', (event) => {
                                    if(document.activeElement.type === 'checkbox'){
                                        event.stopImmediatePropagation();
                                    }
                                })
                                setTimeout(() => {
                                    comp.focus();
                                }, 100);
                            }else if((!($L(actCellFocusable).attr('click'))) && activeCell.querySelector('[focusable]').tabIndex){
                                activeCell.dispatchEvent(mev);
                                actCellFocusable.focus();
                            }else{
                                actCellFocusable.focus();
                            }
                        }else if(activeCell.attributes && (activeCell.attributes.click || activeCell.attributes.onclick)){
                            activeCell.click();
                        }else{
                            activeCell.focus();
                        }
                        if(table.attributes && !table.attributes.listener){
                            table.addEventListener('keydown', mixinFuncCall.actionCompEvent);
                            table.setAttribute('listener', true);
                        }
                        if(modalContainer && modalContainer.component && modalContainer.component.parent && modalContainer.component.parent.tagName === 'LYTE-MODAL'){
                            modalContainer.component.parent.ltProp('closeOnEscape', 'false');
                        }
                    }
                }else if(event.key === 'Tab'){
                    preventTab = true;
                    if(!table.querySelector('.cxAriaActive')){
                        var isTableLis = table.querySelector('[listener]');
                        if(isTableLis && !(isTableLis.tagName.includes('TH') || isTableLis.tagName.includes('TD'))){
                            isTableLis.removeEventListener(mixinFuncCall.subActionCompEvent);
                            $L(isTableLis).removeAttr('listener');
                        }
                        if((table.attributes && table.attributes.listener) && (isTableLis.tagName.includes('TH') || isTableLis.tagName.includes('TD'))){
                            table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
                            $L(table).removeAttr('listener');
                        }
                    }
                    var tableCellsWithFocusables = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp, [focusable]');
                    if(table.querySelector('.cxPrevAriaActive')){
                        $L('.cxPrevAriaActive', table).removeClass('cxPrevAriaActive');
                    }
                    if(event.shiftKey){
                        tableCellsWithFocusables[0].focus({preventScroll : true});
                    }else{
                        var tableCellLen = tableCellsWithFocusables.length;
                        tableCellsWithFocusables[tableCellLen-1].focus({preventScroll : true}); 
                    }
                }
            }
            var currAct = $L('.cxAriaActive', lyteTable);
            if(currAct && !preventTab){
                var currActLeft = currAct[0].offsetLeft; //eslint-disable-line @zoho/webperf/layout-thrashing
                var currActWidth = currAct[0].offsetWidth; //eslint-disable-line @zoho/webperf/layout-thrashing
                var scrollDiv = currAct.cxGetScrollParent();
                if(scrollDiv.scrollLeft > currActLeft){ //eslint-disable-line @zoho/webperf/layout-thrashing
                    scrollDiv.scrollLeft =  currActLeft;
                }else if(currActLeft+currActWidth > scrollDiv.scrollLeft+scrollDiv.offsetWidth){ //eslint-disable-line @zoho/webperf/layout-thrashing
                    scrollDiv.scrollLeft += (currActLeft+currActWidth)-(scrollDiv.scrollLeft+scrollDiv.offsetWidth); //eslint-disable-line @zoho/webperf/layout-thrashing
                }
            }
            if(table.querySelector('.lyteTableFixed')){
                var activeElement = document.activeElement;
                var curr_cell = activeElement;
                var curr_row = activeElement;
                while(!curr_row.tagName.includes('TR')){
                    if(curr_row.tagName.includes('TD') || curr_row.tagName.includes('TH')){
                        curr_cell = curr_row;
                    }
                    curr_row = curr_row.parentElement;
                }
				var curr_row_cells = Array.from(curr_row.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, [focusable]'));
				var curr_cell_index = curr_row_cells.indexOf(activeElement);
				var next_cell = curr_row_cells[curr_cell_index+1];
                if(event.shiftKey){
                    next_cell = curr_row_cells[curr_cell_index-1];
                }
                if(next_cell){
                    while(!(next_cell.tagName.includes('TD') || next_cell.tagName.includes('TH'))){
                        next_cell = next_cell.parentElement;
                    }
                }
                if((activeElement.className && !activeElement.className.includes('lyteTableFixed')) || (event.key === 'Tab' && next_cell && next_cell.className && !next_cell.className.includes('lyteTableFixed'))){
                    if(event.key === 'Tab'){
						curr_cell = next_cell;
					}
					var scrollElement = activeElement;
					var tempScrollElement = activeElement;
                    if(scrollElement.parentElement){
                        tempScrollElement = scrollElement.parentElement.querySelector('[scroll]');
                    }
                    while((!tempScrollElement) && scrollElement !== table){
                        scrollElement = scrollElement.parentElement;
                        if(scrollElement){
                            tempScrollElement = scrollElement.parentElement;
                            tempScrollElement = tempScrollElement.querySelector('[scroll]'); // eslint-disable-line @zoho/webperf/no-multipleDOMLookup
                        }
                    }
                    var fixedElement = curr_row.querySelectorAll('.lyteTableFixed');
                    var fixedElemIndex = fixedElement.length-1;
                    var fixedElementSel = $L(fixedElement[fixedElemIndex]);
                    while(fixedElementSel.css('display') === 'none' && fixedElemIndex!==0){ // eslint-disable-line @zoho/webperf/no-multipleDOMLookup
                        fixedElemIndex--;
                    }
                    var width = fixedElement[fixedElemIndex].clientWidth; // eslint-disable-line @zoho/webperf/layout-thrashing
                    var fixedElementOffset = fixedElementSel.offset(); // eslint-disable-line @zoho/webperf/layout-thrashing
                    var activeElementOffset = $L(curr_cell).offset();
                    if(curr_cell && !curr_cell.className.includes('lyteFixedColumn') && fixedElementOffset && fixedElementOffset.left && activeElementOffset && activeElementOffset.left && activeElementOffset.left<fixedElementOffset.left+width){
                        var leftVal = $L(scrollElement).scrollLeft() - ((fixedElementOffset.left - activeElementOffset.left)+width);
                        $L(scrollElement).scrollLeft(leftVal);
                    }
                }
            }
        }
    },
    actionCompEvent : function(){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var table = event.currentTarget;
        var modalContainer = table.modalContainer;
        var tableRow = table.querySelectorAll('lyte-tr, lyte-exptable-tr, tr');

        if(table.attributes && !table.attributes.listener){
            table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
        }else{
            var actElem = document.activeElement;
            while(!(actElem.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' || actElem.tagName.includes('TR') || actElem.tagName === 'BODY')){
                actElem = actElem.parentElement;
            }
            if((!document.querySelector('.lyteBodyWrapper')) && (actElem.tagName !== 'CRUX-LOOKUPFILTER-COMPONENT' || event.key === 'ArrowUp' || event.key === 'ArrowDown')){
                var mev = new Event("mouseover");
                var mout = new Event("mouseout");
                var prevActElem = document.activeElement;
                var changeFocus = true;
                if((prevActElem.tagName === 'INPUT' && prevActElem.type === 'text') && (event.key === 'ArrowLeft' || event.key === 'ArrowRight')){
                    changeFocus = false
                    var eleLength = prevActElem.value.length;
                    var curPos = event.target.selectionStart;
                    if((eleLength === curPos && event.key === 'ArrowRight') || (curPos === 0 && event.key === 'ArrowLeft')){
                        changeFocus = true;
                    }
                }
                if(changeFocus && event.key.includes('Arrow') && !document.querySelector('.lyteDropdownDown, .lyteDropdownUp, .lyteDropdownRight, .lyteDropdownLeft')){
                    event.preventDefault();
                    var i;
                    var t;
                    var cell;
                    var prevElem;
                    var nextElem;
                    if(event.key === 'ArrowLeft' || event.key === 'ArrowRight'){
                        var row = document.activeElement;
                        cell = row;
                        while(!(row.tagName.includes('TR') || row.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' || row.tagName === 'BODY')){
                            if(row.tagName.includes('TH') || row.tagName.includes('TD')){
                                cell = row;
                            }
                            row = row.parentElement;
                        }
                        // var focusableElements = row.querySelectorAll('[focusable]:not(.cxSkipFocusable)');
                        var focusableElements = Array.from(row.querySelectorAll('[focusable]:not(.cxSkipFocusable)'));
                        if(cell.attributes && cell.attributes.cxFocusableSelector && cell.attributes.cxFocusableSelector.value){
						    var focusableSelector = $L(cell).attr('cxFocusableSelector');
                            if(focusableSelector){
                                var focusableSelectorElem = document.querySelector(focusableSelector);
                                if(focusableSelectorElem){
                                    focusableElements.push(focusableSelectorElem);
                                    mixinFuncCall.addLabel.call(table, cell);
                                }
                            }
                        }
                        var focusableElementsLen = focusableElements.length;
                        for(i=0;i<focusableElementsLen;i++){
                            if(document.activeElement === focusableElements[i] && (!focusableElements[i].querySelector('[focusable]:not(.cxSkipFocusable)'))){
                                prevElem = focusableElements[i];
                                t = i;
                                do{
                                    if(event.key === 'ArrowLeft'){
                                        t = (t === 0)?focusableElementsLen-1:t-1;
                                    }else if(event.key === 'ArrowRight'){
                                        t = (t === focusableElementsLen-1)?0:t+1;
                                    }
                                    if(!($L(focusableElements[t]).attr('click'))){
                                        row.dispatchEvent(mev);
                                    }
                                    //For new rendering elements on click
                                    nextElem = focusableElements[t];
                                    while(!((prevElem.tagName.includes('TD') || prevElem.tagName.includes('TH')) && (nextElem.tagName.includes('TD') || nextElem.tagName.includes('TH')))){
                                        if(!(prevElem.tagName.includes('TD') || prevElem.tagName.includes('TH'))){
                                            prevElem = prevElem.parentElement;
                                        }
                                        if(!(nextElem.tagName.includes('TD') || nextElem.tagName.includes('TH'))){
                                            nextElem = nextElem.parentElement;
                                        }
                                    }
                                    //for table in detail view page
                                    focusableElements[t].focus();
                                }while(prevActElem === document.activeElement && i !== t);
                                break;
                            }
                        }
                    }else{
                        var tableRowLen = tableRow.length;
                        for(i=0;i<tableRowLen;i++){
                            if(tableRow[i].querySelector('.cxAriaActive')){
                                var br = false;
                                cell = tableRow[i].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                                var cellLen = cell.length;
                                for(var j=0;j<cellLen;j++){
                                    if(cell[j] && cell[j].className && cell[j].className.includes('cxAriaActive') || cell[j].querySelector('.cxAriaActive')){
                                        prevElem = document.activeElement;
                                        t = i;
                                        tableRow[t].dispatchEvent(mout);
                                        if(event.key === 'ArrowUp'){
                                            t = (t === 0)?tableRowLen-1:t-1;
                                        }else if(event.key === 'ArrowDown'){
                                            t = (t === tableRowLen-1)?0:t+1;
                                        }
                                        var focusElem = tableRow[t].querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td')[j];
                                        tableRow[t].dispatchEvent(mev);
                                        //For new rendering elements on click
                                        nextElem = focusElem;
                                        while(!((prevElem.tagName.includes('TD') || prevElem.tagName.includes('TH')) && (nextElem.tagName.includes('TD') || nextElem.tagName.includes('TH')))){
                                            if(!(prevElem.tagName.includes('TD') || prevElem.tagName.includes('TH'))){
                                                prevElem = prevElem.parentElement;
                                            }
                                            if(!(nextElem.tagName.includes('TD') || nextElem.tagName.includes('TH'))){
                                                nextElem = nextElem.parentElement;
                                            }
                                        }
                                        //for table in detail view page
                                        if(focusElem.querySelector('[focusable]:not(.cxSkipFocusable)') && (!focusElem.className.includes('cxLookupFieldList')) && (!nextElem.querySelector('.cxSetFocus'))){
                                        // if(focusElem.querySelector('[focusable]:not(.cxSkipFocusable)') && (!focusElem.className.includes('cxLookupFieldList'))){
                                            focusElem.querySelector('[focusable]:not(.cxSkipFocusable)').focus();
                                        }else if(focusElem.attributes.focusable){
                                            focusElem.focus();
                                        }
                                        br = true;
                                        break;
                                    }
                                }
                                if(br){
                                    break;
                                }
                            }
                        }
                    }
                    event.stopPropagation();
                }else if(event.key === ' ' || event.key === 'Enter'){
                    event.preventDefault();
                    var activeFocusableElem = document.activeElement;
                    var actElement = activeFocusableElem;
                    while(!(actElement.tagName.includes('TR') || actElement.tagName === 'BODY')){
                        actElement = actElement.parentElement;
                    }
                    if(activeFocusableElem.type === 'checkbox'){
                        var comp = activeFocusableElem;
                        $L(actElement).on('click', (event) => {
                            if(document.activeElement.type === 'checkbox'){
                                event.stopImmediatePropagation();
                            }
                        })
                        setTimeout(() => {
                            comp.focus();
                        }, 100);
                    }else{
                        activeFocusableElem.dispatchEvent(mev);
                        activeFocusableElem.click();
                    }
                    setTimeout(() => {
                        if(document.querySelector('[pos]:not(.lyteMenuClosed, .lyteMenuHidden)')){
                            activeFocusableElem.addEventListener('keydown', mixinFuncCall.subActionCompEvent);
                            activeFocusableElem.setAttribute('listener', true);
                            activeFocusableElem.table = table;
                        }
                    }, 100);
                    event.stopImmediatePropagation();
                }else if(event.key === 'Escape'){
                    var activeFocusableElement = document.activeElement;
                    var activeFocusableCell = activeFocusableElement;
                    var activeRow = activeFocusableCell;

                    while(!(activeRow.tagName.includes('TR') || activeRow.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' || activeRow.tagName === 'BODY')){
                        if(activeRow.tagName.includes('TH') || activeRow.tagName.includes('TD')){
                            activeFocusableCell = activeRow;
                        }
                        activeRow = activeRow.parentElement;
                    }
                    if(activeFocusableCell.querySelector('.cxSetFocus') && activeFocusableCell.querySelector('.cxCloseEdit')){
                        activeFocusableCell.querySelector('.cxCloseEdit').click();
                    }
                    if(event.target === activeFocusableElement){
                        activeRow.dispatchEvent(mout);
                        activeFocusableCell.focus();
                        activeFocusableCell.dispatchEvent(mev);
						
                        if(table.attributes && table.attributes.listener){
                            table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
                            $L(table).removeAttr('listener');
                        }
                        if(!(table.attributes.listener || table.querySelector('[subKeyListen]')) && modalContainer && modalContainer.component && modalContainer.component.parent && modalContainer.component.parent.tagName === 'LYTE-MODAL'){
                            modalContainer.component.parent.ltProp('closeOnEscape', 'true');
                        }
                    }
                }else if(event.key === 'Tab'){
                    var tableCellsWithFocusables = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp, [focusable]');
                    if(event.shiftKey){
                        tableCellsWithFocusables[0].focus();
                    }else{
                        var tableCellsFocusableLen = tableCellsWithFocusables.length;
                        tableCellsWithFocusables[tableCellsFocusableLen-1].focus(); 
                    }
                }
            }
        }
    },
    subActionCompEvent : function(){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var table = event.currentTarget.table;
        var activeFocusableElem = document.activeElement;
        var parentCruxComp = table.parentCruxComp;
        if(parentCruxComp.component && parentCruxComp.component.data && parentCruxComp.component.data.showSortableOptions){
            var sortMenu = parentCruxComp.querySelector('#sortOrderMenu');
            if(sortMenu && sortMenu.component && sortMenu.component.childComp){
                if(activeFocusableElem && $L(activeFocusableElem).attr('aria-activedescandant') !== 'sortOptionSelection'){
                    activeFocusableElem.setAttribute('aria-activedescendant', 'sortOptionSelection');
                }
                var sortOptionSelection = sortMenu.component.childComp.querySelector('.lyteMenuSelection');
                if(sortOptionSelection && sortOptionSelection.id !== 'sortOptionSelection'){
                    $L('#sortOptionSelection').removeAttr('id');
                    sortOptionSelection.id = 'sortOptionSelection';
                }
            }
        }
        if(table.attributes && table.attributes.listener){
            table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
            $L(table).removeAttr('listener');
        }
        var mev = new Event("mouseover");
        if(event.key === 'Escape'){
            if(table.attributes && !table.attributes.listener){
                table.addEventListener('keydown', mixinFuncCall.actionCompEvent);
                $L(table).attr('listener', true);
            }
            activeFocusableElem.dispatchEvent(mev);
            activeFocusableElem.focus();
            if(activeFocusableElem.attributes && activeFocusableElem.attributes.listener){
                activeFocusableElem.removeEventListener('keydown', mixinFuncCall.subActionCompEvent);
                $L(activeFocusableElem).removeAttr('listener');
            }
        }else if(event.key.includes('Arrow')){
            event.preventDefault();
        }else if(event.key === 'Tab'){
            var tableCellsWithFocusables = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td, .noResultAriaComp, [focusable]');
            if(event.shiftKey){
                tableCellsWithFocusables[0].focus();
            }else{
                var tableCellsLen = tableCellsWithFocusables.length;
                tableCellsWithFocusables[tableCellsLen-1].focus(); 
            }
        }else{
            var posEle = document.querySelectorAll('[pos]:not(.lyteMenuClosed)');
            var posEleLen = posEle.length;
            if(posEleLen === 0){
                if(table.attributes && !table.attributes.listener){
                    table.addEventListener('keydown', mixinFuncCall.actionCompEvent);
                    $L(table).attr('listener', true);
                }
                activeFocusableElem.dispatchEvent(mev);
                activeFocusableElem.focus();
                if(activeFocusableElem.attributes && activeFocusableElem.attributes.listener){
                    activeFocusableElem.removeEventListener('keydown', mixinFuncCall.subActionCompEvent);
                    $L(activeFocusableElem).removeAttr('listener');
                }
            }
        }
        event.stopImmediatePropagation();
    },
    bindEventForAria : function(){
        console.log('bind');
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var _this = this;
        if(this.$node){
            _this = this.$node;
        }
        setTimeout(() => {
            var allTable = _this.querySelectorAll('lyte-table:not(.cxPropAriaSkip), lyte-expresstable:not(.cxPropAriaSkip), table:not(.cxPropAriaSkip)');
            if(_this){
                var allTableLen = allTable.length;
                for(var index = 0; index < allTableLen; index++){
                    var table = allTable[index];
                    if( (((table.tagName === 'LYTE-TABLE' || table.tagName === 'LYTE-EXPRESSTABLE') && table.ltProp('aria')) || $L(table).attr('lt-prop-aria')) || (( table.tagName === 'CRUX-TABLE-COMPONENT' && table.cxProp('aria') ) || $L(table).attr('cx-prop-aria')) ){
                        var cruxTable = table;
                        var lyteTable = table;
                        var parentCruxComp = table;
                        var parentWormHole = table;
                        var traverseVar = table;
                        while(traverseVar.tagName !== 'BODY' && table.tagName !== 'TABLE'){
                            if(traverseVar.tagName.includes('CRUX') && traverseVar.tagName !== 'CRUX-TABLE-COMPONENT'){
                                parentCruxComp = traverseVar;
                            }
                            if(traverseVar.tagName === 'LYTE-TABLE' || traverseVar.tagName === 'LYTE-EXPRESSTABLE'){
                                lyteTable = traverseVar;
                            }else if(traverseVar.tagName === 'CRUX-TABLE-COMPONENT'){
                                cruxTable = traverseVar;
                            }else if(traverseVar._callee && traverseVar._callee.component && traverseVar._callee.component.childComp && traverseVar._callee.component.childComp === parentCruxComp){
                                parentWormHole = traverseVar;
                            }else if(traverseVar.tagName === 'BODY'){
                                break;
                            }
                            if(traverseVar._callee){
                                traverseVar = traverseVar._callee;
                            }else{
                                break;
                            }
                        }
                        if(parentCruxComp.tagName === 'CRUX-TABLE-COMPONENT'){
                            cruxTable = parentCruxComp;
                        }
                        cruxTable.cruxTable = cruxTable;
                        cruxTable.lyteTable = lyteTable;
                        cruxTable.parentCruxComp = parentCruxComp;
                        cruxTable.parentWormHole = parentWormHole;
                        lyteTable.cruxTable = cruxTable;
                        lyteTable.lyteTable = lyteTable;
                        lyteTable.parentCruxComp = parentCruxComp;
                        lyteTable.parentWormHole = parentWormHole;
                        // if(!$L(table).attr('cxAriaTableKeydown')){
                            if(table.lyteTable && table.lyteTable.tagName.includes('LYTE')){
                                table.lyteTable.ltProp('tabIndex', '-1');
                            }
                            var tableCells = table.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                            var dataTabInd = 0;
                            var tableCellsLen = tableCells.length;
                            for(var i=0;i<tableCellsLen;i++){
                                if(!$L(tableCells[i]).attr('lt-prop-data-tabindex') && ($L(table).attr('cx-prop-group-index') || (parentCruxComp.tagName.includes('CRUX') && parentCruxComp.cxProp('groupIndex')))){
                                    var grpIndex = parentCruxComp.tagName.includes('CRUX')?parentCruxComp.cxProp('groupIndex'):$L(table).attr('cx-prop-group-index');
                                    tableCells[i].setAttribute('lt-prop-data-tabindex', 'group'+grpIndex+'-'+dataTabInd);
                                    dataTabInd++;
                                }
                                if(tableCells[i].tabIndex !== 0 || !tableCells[i].ariaLabel){
                                    mixinFuncCall.addLabel.call(table, tableCells[i]);
                                }
                                if(tableCells[i].querySelector('.cxSetFocus')){
                                    mixinFuncCall.clickOnComp(this, tableCells[i]);
                                }
                            }
                            if(cruxTable.tagName.includes('CRUX') && cruxTable.getMethods('afterAriaAdd')){
                                cruxTable.component.executeMethod('afterAriaAdd');
                            }
                            if(parentCruxComp.tagName.includes('CRUX') && parentCruxComp.cxProp('noContentClass') && parentCruxComp.querySelector('.'+parentCruxComp.cxProp('noContentClass'))){
                                var noResult = cruxTable.querySelector('.'+cruxTable.cxProp('noContentClass'));
                                if(noResult.textContent && noResult.textContent !== ''){
                                    noResult.tabIndex = 0;
                                    noResult.ariaLabel = noResult.textContent;
                                    if(!cruxTable.cxProp('noContentClass').includes('noResultAriaComp')){
                                        var noContClass = cruxTable.cxProp('noContentClass');
                                        noContClass += ' noResultAriaComp';
                                        cruxTable.cxProp('noContentClass', noContClass)
                                    }
                                    if(!$L(noResult).attr('focusable')){
                                        $L(noResult).attr('focusable', true);
                                    }
                                    if(!$L(noResult).attr('cxAriaTableFocus')){
                                        noResult.addEventListener('focusin', mixinFuncCall.onFocus.bind(table));
                                        noResult.addEventListener('focusout', mixinFuncCall.onFocusOut.bind(table));
                                        $L(noResult).attr('cxAriaTableFocus', true);
                                    }
                                }
                            }
                            if(!$L(_this).attr('cxAriaTableFocusableParent') || (_this && _this.attributes && _this.attributes.cxAriaTableFocusableParent && !_this.attributes.cxAriaTableFocusableParent.nodeValue)){
                                document.addEventListener('click', mixinFuncCall.clickOnDoc); // eslint-disable-line @zoho/zstandard/no-body-events
                                document.addEventListener('keydown', mixinFuncCall.escOnDoc); // eslint-disable-line @zoho/zstandard/no-body-events
                                $L(_this).attr('cxAriaTableFocusableParent', true);
                            }
                            if(!$L(table).attr('cxAriaTableKeydown')){
                                table.listener = mixinFuncCall.keyEvent.bind(table);
                                table.addEventListener('keydown', table.listener);
                                table.addEventListener('click', mixinFuncCall.clickOnTable);
                                table._this = this;
                                $L(table).attr('cxAriaTableKeydown', true);
                            }
                            if(table.attributes && table.attributes.listener && (!table.querySelector('.cxAriaActive') || !$L('.cxAriaActive', table).attr('focusable'))){
                                $L(table).removeAttr('listener');
                                table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
                            }

                            if(document.querySelector('[cxAriaTableFocusableParent]')){
                                var cxAriaTableFocusableParent = document.querySelector('[cxAriaTableFocusableParent]');
                                if(cxAriaTableFocusableParent && cxAriaTableFocusableParent.component){
                                    cxAriaTableFocusableParent = cxAriaTableFocusableParent.component;
                                    var modalContainer = cxAriaTableFocusableParent;
                                    if(modalContainer && modalContainer.$node){
                                        modalContainer = modalContainer.$node;
                                    }
                                    while(modalContainer && !(modalContainer.component && modalContainer.component.parent) && modalContainer.tagName !== 'LYTE-WORMHOLE' && modalContainer.tagName !== 'BODY'){
                                        modalContainer = modalContainer.parentElement;
                                    }
                                    cruxTable.modalContainer = modalContainer;
                                    lyteTable.modalContainer = modalContainer;
                                }
                            }
                        // }
                    }
                }
            }
        }, 100);
    },
    clickOnComp : function(tableCell){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var hasAccessElement = false;
        setTimeout(() => {
            if(tableCell.childElementCount>0){
                for (let [key, value] of Object.entries(tableCell.children)) {
                    if($L(value).css('display') !== 'none'){
                        hasAccessElement = true;
                        break;
                    }
                }
            }else if(tableCell.textContent){
                hasAccessElement = true;
            }

            var table = tableCell;
            while(table.tagName !== 'BODY'){
                table = table.parentElement;
                if(table.tagName === 'LYTE-TABLE' || table.tagName === 'LYTE-EXPRESSTABLE' || table.tagName === 'TABLE'){
                    break;
                }
            }
            /* detail view table */
            if(hasAccessElement){
                var closestOfActElem = document.activeElement.closest('[focusable]'); // eslint-disable-line @zoho/webperf/no-attribute-selectors
                if(!tableCell.querySelector('.cxSetFocus') && closestOfActElem && closestOfActElem === tableCell){
                    $L(tableCell).addClass('cxSetFocus');
                }
                if(tableCell.className.includes('cxSetFocus') || tableCell.querySelector('.cxSetFocus')){
                    var focusableParentComp = tableCell;
                    if(focusableParentComp.querySelector('.cxSetFocus')){
                        focusableParentComp = focusableParentComp.querySelector('.cxSetFocus');
                    }
                    focusableParentComp.querySelectorAll('[tabIndex], input, [onclick]').forEach(element => {
                        var elementSel = $L(element);
                        if(elementSel.css('display') !== 'none' && elementSel.css('visibility') !== 'hidden' && !element.className.includes('hidden') && element.tabindex !== -1){ // eslint-disable-line @zoho/webperf/layout-thrashing
                            elementSel.attr('focusable', true);
                            if((element.tagName.includes('TD') || element.tagName.includes('TH')) && element.tabIndex === -1){
                                element.tabIndex = 0;
                                if(!element.ariaLabel){
                                    if(element.value){
                                        var eleVal = element.value;
                                        if(eleVal){
                                            element.ariaLabel = eleVal;
                                        }
                                    }else if(element.textContent && element.textContent.trim()!==''){
                                        element.ariaLabel = element.textContent.trim();
                                    }
                                }
                            }
                        }
                        if(element.attributes && element.attributes.onclick){
                            $L(element).on('click', () => {
                                focusableParentComp.focus();
                                if((table.tagName === 'LYTE-TABLE' || table.tagName === 'LYTE-EXPRESSTABLE' || table.tagName === 'TABLE') && element.attributes && table.attributes.listener){
                                    table.removeEventListener('keydown', mixinFuncCall.actionCompEvent);
                                    $L(table).removeAttr('listener');
                                }
                                table.querySelectorAll('[nonFocusable]').forEach(element => {
                                    if((element.tagName.includes('TH') || element.tagName.includes('TD')) && table && table.attributes && !table.attributes.listener){
                                        $L(element).attr('focusable', 'true');
                                        $L(element).removeAttr('nonFocusable');
                                    }
                                })
                            })  
                        }
                        focusableParentComp.querySelectorAll('[focusable]:not(.cxSkipFocusable)').forEach(element => {
                            if(element.querySelector('[focusable]:not(.cxSkipFocusable)')){
                                $L(element).removeAttr('focusable');
                            }
                        });
                    });

                //     table.querySelectorAll('[focusable]').forEach(element => {
                //         if((element.tagName.includes('TH') || element.tagName.includes('TD')) && table && table.attributes && table.attributes.listener){
                //             $L(element).removeAttr('focusable');
                //             $L(element).attr('nonFocusable', 'true');
                //         }
                //     })
                }
            }
        }, 500);
    },
    clickOnTable : function(){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var activeElem = document.activeElement;
        var table = event.currentTarget;
        var targetElem = event.target;
        setTimeout(() => {
            mixinFuncCall.addLabel.call(table, activeElem);

            var cruxTable = table.cruxTable;
            var lyteTable = table.lyteTable;
            var parentCruxComp = table.parentCruxComp;
            var modalContainer = table.modalContainer;
            if(cruxTable.tagName.includes('CRUX') && cruxTable.getMethods('afterAriaAdd')){
                cruxTable.component.executeMethod('afterAriaAdd');
            }
            activeElem = document.activeElement;
            if(activeElem.attributes && activeElem.attributes.focusable && (!(activeElem.tagName.includes('TD') || activeElem.tagName.includes('TH')) || (parentCruxComp.component && parentCruxComp.component.data && parentCruxComp.component.data.showSortableOptions)) && table.querySelector('.cxAriaActive:not(crux-lookupfilter-component .cxAriaActive)')){
                if(parentCruxComp.component && parentCruxComp.component.data && parentCruxComp.component.data.showSortableOptions){
                    var sortMenu = parentCruxComp.querySelector('#sortOrderMenu');
                    if(sortMenu && sortMenu.component && sortMenu.component.childComp){
                        if(activeElem && $L(activeElem).attr('aria-activedescandant') !== 'sortOptionSelection'){
                            activeElem.setAttribute('aria-activedescendant', 'sortOptionSelection');
                        }
                        var sortOptionSelection = sortMenu.component.childComp.querySelector('.lyteMenuSelection');
                        if(sortOptionSelection && sortOptionSelection.id !== 'sortOptionSelection'){
                            $L('#sortOptionSelection').removeAttr('id');
                            sortOptionSelection.id = 'sortOptionSelection';
                        }
                    }
                }
                if(lyteTable.attributes && !lyteTable.attributes.listener){
                    lyteTable.addEventListener('keydown', mixinFuncCall.actionCompEvent)
                    $L(lyteTable).attr('listener', true);
                }
                if(activeElem.attributes && !activeElem.attributes.listener){
                    setTimeout(() => {
                        if(document.querySelector('[pos]:not(.lyteMenuClosed, .lyteMenuHidden)')){
                            activeElem.addEventListener('keydown', mixinFuncCall.subActionCompEvent);
                            $L(activeElem).attr('listener', true);
                            activeElem.table = table;
                        }
                    }, 100);
                }
            }else if((activeElem.tagName.includes('TD') || activeElem.tagName.includes('TH')) && !activeElem.querySelector('[focusable]:not(.cxSkipFocusable)') && !(activeElem.attributes.click || activeElem.attributes.onclick) && !activeElem.querySelector('[focusable]:not(lyte-td[focusable], lyte-th[focusable], lyte-exptable-td[focusable], lyte-exptable-th[focusable], td[focusable], th[focusable], .cxSkipFocusable)')){
                $L(lyteTable).removeAttr('listener');
            }

            var mev = new Event("mouseover");
            var mout = new Event("mouseout");
            var activeFocusableElem
            if(document.querySelector('.cxAriaActive')){
                activeFocusableElem = document.querySelector('.cxAriaActive');
            }else{
                activeFocusableElem = document.querySelector('.cxPrevAriaActive');
            }

            if(activeFocusableElem){
                var actCell = activeFocusableElem;
                var activeRow = activeFocusableElem;

                while(!(activeRow.tagName.includes('TR') || activeRow.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' || activeRow.tagName === 'BODY')){
                    if(activeRow.tagName.includes('TH') || activeRow.tagName.includes('TD')){
                        actCell = activeRow;
                    }
                    activeRow = activeRow.parentElement;
                }
                if(!(lyteTable.attributes.listener || lyteTable.querySelector('[subKeyListen]')) && modalContainer && modalContainer.component && modalContainer.component.parent && modalContainer.component.parent.tagName === 'LYTE-MODAL' && !activeFocusableElem.attributes.focusable){
                    modalContainer.component.parent.ltProp('closeOnEscape', 'true');
                }else if(modalContainer && modalContainer.component && modalContainer.component.parent && modalContainer.component.parent.ltProp('closeOnEscape')){
                    modalContainer.component.parent.ltProp('closeOnEscape', 'false');
                }
                if(activeRow.tagName === 'CRUX-LOOKUPFILTER-COMPONENT' && activeRow.attributes.listener){
                    $L(activeRow).removeAttr('listener');
                }
            }
        }, 300);
    },
    clickOnDoc : function(){
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var focusableParent = document.querySelector('[cxAriaTableFocusableParent]');
        if(focusableParent && $L(focusableParent).css('visibility') !== 'hidden'){
            var activeElem = document.activeElement;
            var table = event.target;
            if(activeElem.tagName === 'BODY' && document.querySelector('lyte-table .cxPrevAriaActive, lyte-expresstable .cxPrevAriaActive, table .cxPrevAriaActive, lyte-table .cxAriaActive, lyte-expresstable .cxAriaActive, table .cxAriaActive')){
                table = document.querySelector('.cxPrevAriaActive');
                while(table.tagName !== 'TABLE' && table.tagName !== 'LYTE-TABLE' && table.tagName !== 'LYTE-EXPRESSTABLE'){
                    table = table.parentElement;
                    if(table.tagName === 'BODY'){
                        break;
                    }
                }
                var lyteTable = table.lyteTable;
                table.removeEventListener('keydown', mixinFuncCall.actionCompEvent)
                if(table.attributes.listener){
                    $L(table).removeAttr('listener');
                }
                lyteTable.removeEventListener('keydown', mixinFuncCall.actionCompEvent)
                if(lyteTable.attributes.listener){
                    $L(lyteTable).removeAttr('listener');
                }
                if(activeElem.tagName === 'BODY'){
                    if(lyteTable.querySelector('.cxAriaActive')){
                        activeElem = lyteTable.querySelector('.cxAriaActive');
                    }else if(lyteTable.querySelector('.cxPrevAriaActive')){
                        activeElem = lyteTable.querySelector('.cxPrevAriaActive');
                    }
                }
                activeElem.removeEventListener('keydown', mixinFuncCall.subActionCompEvent);
                if(activeElem.attributes.listener){
                    $L(activeElem).removeAttr('listener');
                }
            }

            setTimeout(() => {
                if((!focusableParent) || $L(focusableParent).css('visibility') === 'hidden'){
                    document.removeEventListener('keydown', mixinFuncCall.escOnDoc);
                    document.removeEventListener('click', mixinFuncCall.clickOnDoc);
                    $L(focusableParent).removeAttr('cxAriaTableFocusableParent');
                }
            }, 100);
        }
        setTimeout(() => {
            if((!focusableParent || $L(focusableParent).css('visibility') === 'hidden')){
                document.removeEventListener('keydown', mixinFuncCall.escOnDoc);
                document.removeEventListener('click', mixinFuncCall.clickOnDoc);
                if(focusableParent && $L(focusableParent).css('visibility') === 'hidden'){
                    $L(focusableParent ).attr('cxAriaTableFocusableParent', false);
                }
            }
        }, 100);
    },
    addLabel : function(tableCell){
        if((tableCell.tagName.includes('TD') || tableCell.tagName.includes('TH')) && tableCell.tabIndex === -1){
            tableCell.tabIndex = 0;
        }
        var mixinFuncCall = Lyte.registeredMixins['crux-aria-table-mixin'];
        var compBind = this;
        if(this.$node){
            compBind = this.$node;
        }
        /* find crux-table-component */
        var cruxTable = compBind.cruxTable;
        var lyteTable = compBind.lyteTable;
        var table = compBind;
        if(lyteTable.tagName.includes('LYTE') && table.tagName !== 'TABLE'){
            table = lyteTable;
        }
        /* checking if tablecell has focusable children */
        var hasAccessElement = false;
        if(tableCell.childElementCount>0){
            for (let [key, value] of Object.entries(tableCell.children)) {
                if($L(value).css('display') !== 'none'){
                    hasAccessElement = true;
                    break;
                }
            }
        }else if(tableCell.textContent){
            hasAccessElement = true;
        }else if($L(tableCell).css('display') !== 'none'){
            hasAccessElement = true;
        }
        /* get row of current cell */
        var tableRow = tableCell;
        if(tableCell.tagName.includes('TD') || tableCell.tagName.includes('TH')){
            while(!tableRow.tagName.includes('TR')){
                tableRow = tableRow.parentElement;
            }
        }
        /* detail view table */
        if(hasAccessElement){
            /* cxSetFocus - element has to be focus */
            // if(tableCell.querySelector('.cxSetFocus')){
            //     mixinFuncCall.clickOnComp(tableCell);
            // }else{
                if(!(tableCell.querySelector('table') || tableCell.querySelector('lyte-table') || tableCell.querySelector('lyte-expresstable'))){
                    /* get record id to set label for radio button or checkbox */
                    var recordId = "";
                    /* get record name column index to add name in label for radio button or checkbox */
                    var headerColInd = ""
                    if(cruxTable && cruxTable.component && cruxTable.component.data && cruxTable.component.data.cxPropModule && moduleRecordMapping[cruxTable.component.data.cxPropModule] && moduleRecordMapping[cruxTable.component.data.cxPropModule].display_field && moduleRecordMapping[cruxTable.component.data.cxPropModule].display_field.id && !tableCell.tagName.includes('TH')){
                        recordId = moduleRecordMapping[cruxTable.component.data.cxPropModule].display_field.id;
                        if(!document.getElementById(recordId)){
                            recordId = moduleRecordMapping[cruxTable.component.data.cxPropModule].fields.filterBy({api_name : "Last_Name"})[0].id
                        }
                        if(document.getElementById(recordId)){
                            headerColInd = document.getElementById(recordId)._cx.itemIndex;
                        }

                    }else if(lyteTable && lyteTable.id.includes('cruxUserLookupTable') && cruxTable && cruxTable._callee){
                        var cruxComp = cruxTable._callee;
                        while(!cruxComp.tagName.includes('CRUX')){
                            cruxComp = cruxComp._callee;
                            if(cruxComp.tagName === 'BODY'){
                                cruxComp = cruxTable._callee;
                                break;
                            }
                        }
                        var i;
                        if(cruxComp.tagName.includes('CRUX')){
                            cruxComp = cruxComp.component.data.cxPropHeader;
                            var cruxCompLen = cruxComp.length;
                            for(i=0;i<cruxCompLen;i++){
                                if(cruxComp[i].systemValue === 'full_name'){
                                    recordId = cruxComp[i].displayValue;
                                    break;
                                }
                            }
                        }
                        var headerCols = table.querySelectorAll('lyte-th, lyte-exptable-th, th');
                        var headerColsLen = headerCols.length;
                        for(i=0;i<headerColsLen;i++){
                            if(headerCols[i].textContent === recordId){
                                headerColInd = i;
                            }
                        }
                    }
                    var contentCells = tableRow.querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                    var elemLabel = '';
                    /* add header name to all cells by header row id */
                    if(tableCell.tagName.includes('TD') && (cruxTable.tagName.includes('CRUX') || lyteTable.tagName.includes('LYTE'))){
                        var headerRowId = '';
                        var headerCells = '';
                        if(cruxTable.tagName.includes('CRUX')  && cruxTable.component && cruxTable.component.data && cruxTable.component.data.cxPropHeaderRowId){
                            headerRowId = cruxTable.component.data.cxPropHeaderRowId;
                            headerCells = cruxTable.querySelector('#'+headerRowId).querySelectorAll('lyte-th, lyte-td, lyte-exptable-th, lyte-exptable-td, th, td');
                        }else if(table.querySelectorAll('lyte-th, lyte-exptable-th, th')){
                            headerCells = table.querySelectorAll('lyte-th, lyte-exptable-th, th');
                        }
                        contentCells.forEach((ele, index) => {
                            if(ele === tableCell && headerCells[index] && headerCells[index].textContent.trim() !== ''){
                                elemLabel += headerCells[index].textContent.trim();
                            }
                        })
                    }
                    /* add space if elemLabel contains content */
                    var elemLabelLen = elemLabel.length;
                    if(elemLabelLen>0){
                        elemLabel = elemLabel.trim()+' ';
                    }
                    var tableCellTitle = tableCell.querySelector('[lt-prop-title]');
                    var lyteLabelText = tableCell.querySelector('#labelText');
                    if((tableCell.querySelector('lyte-checkbox') || tableCell.querySelector('lyte-radiobutton')) && headerColInd !== ""){
                        elemLabel = elemLabel.trim();
                        elemLabel += 'select record ';
                        /* get name of record and add to elemLabel */
                        contentCells.forEach((ele, index) => {
                            if(index === headerColInd){
                                elemLabel += ele.textContent.trim();
                            }
                        })
                    }else if(tableCell.tagName.includes('TH')){
                        if(lyteLabelText){
                            elemLabel += lyteLabelText.textContent.trim();
                        }else if(tableCell.textContent.trim()){
                            elemLabel += tableCell.textContent.trim();
                        }else if(tableCell.attributes && tableCell.attributes['lt-prop-title'] && tableCell.attributes['lt-prop-title'].nodeValue){
                            elemLabel += tableCell.attributes['lt-prop-title'].nodeValue.trim();
                        }else if(tableCellTitle && tableCellTitle.attributes && tableCellTitle.attributes['lt-prop-title'] && tableCellTitle.attributes['lt-prop-title'].nodeValue){
                            elemLabel += tableCellTitle.attributes['lt-prop-title'].nodeValue.trim();
                        }
                    }else if(!(tableCell.tagName.includes('TH') || tableCell.tagName.includes('TD'))){
                        var cellElem = tableCell;
                        while(!(cellElem.tagName.includes('TH') || cellElem.tagName.includes('TD'))){
                            cellElem = cellElem.parentElement;
                            if(cellElem.tagName.includes('TR') || cellElem.tagName.includes('BODY') || cellElem.tagName.includes('TABLE')){
                                break;
                            }
                        }
                        if((cellElem.tagName.includes('TH') || cellElem.tagName.includes('TD')) && cellElem.ariaLabel && !tableCell.ariaLabel && elemLabel === ''){
                            elemLabel = cellElem.ariaLabel.trim();
                        }
                    }
                    /* add space if elemLabel contains content */
                    elemLabelLen = elemLabel.length;
                    if(elemLabelLen>0){
                        elemLabel = elemLabel.trim()+' ';
                    }
                    if(tableCell.querySelector('[tabIndex]')){
                        if(tableCell.querySelector('[tabIndex]').tagName.includes('CRUX') || tableCell.querySelector('[tabIndex]').tagName.includes('LYTE')){
                            var compName = tableCell.querySelector('[tabIndex]').tagName.split('-')[1];
                            elemLabel += compName.trim();
                        }else if(!elemLabel.includes('select')){
                            elemLabel = elemLabel.trim();
                            if(tableCell.querySelector('a') || tableCell.querySelector('[tabIndex]').className.includes('link') || tableCell.querySelector('[tabIndex]').className.includes('Link')){ 
                                elemLabel += ' link';
                            }else if(tableCell.textContent.trim() !== ''){
                                elemLabel += ' text';
                            }
                        }
                    }
                    var addFocusCells = Array.from(tableCell.querySelectorAll('[tabIndex]:not(.cxSkipFocusable), [click]:not(.cxSkipFocusable), [focus]:not(.cxSkipFocusable), [focusable]:not(.cxSkipFocusable), crmutil-icon:not(.cxSkipFocusable), a:not(.cxSkipFocusable), button:not(.cxSkipFocusable)'));
                    if(tableCell.attributes && tableCell.attributes.cxFocusableSelector && tableCell.attributes.cxFocusableSelector.value){
                        var focusableSelector = $L(tableCell).attr('cxFocusableSelector');
                        if(focusableSelector){
                            var focusableSelectorElem = document.querySelector(focusableSelector);
                            if(focusableSelectorElem){
                                addFocusCells.push(focusableSelectorElem);
                            }
                        }
                    }
                    var addFocusCellsLen = addFocusCells.length;
                    for(var k=0;k<addFocusCellsLen;k++){
                        /* add label for lyte components */
                        if(addFocusCells[k].tagName.includes('LYTE') || (addFocusCells[k]._callee && addFocusCells[k]._callee.tagName.includes('LYTE'))){
                            var closeestLyteElem = addFocusCells[k];
                            if(!addFocusCells[k].tagName.includes('LYTE')  && addFocusCells[k]._callee.tagName.includes('LYTE')){
                                closeestLyteElem = addFocusCells[k]._callee;
                            }
                            if(!closeestLyteElem.component.data.ltPropAria){
                                closeestLyteElem.ltProp('aria', true);
                            }
                            if(elemLabel.includes('text')){
                                elemLabel = elemLabel.replace('text', '').trim();
                            }else if(elemLabel.includes('link')){
                                elemLabel = elemLabel.replace('link', '').trim();
                            }

                            elemLabel = elemLabel.trim();
                            if(closeestLyteElem.tagName === 'LYTE-DROPDOWN'){
                                closeestLyteElem.ltProp('ariaButton', {'aria-label':elemLabel});
                            }else if(closeestLyteElem.tagName === 'LYTE-CHECKBOX'){
                                closeestLyteElem.ltProp('ariaCheckbox', {'aria-label':elemLabel});
                            }else if(closeestLyteElem.tagName === 'LYTE-RADIOBUTTON'){
                                closeestLyteElem.ltProp('ariaRadio', {'aria-label':elemLabel});
                            }else if(closeestLyteElem.tagName.includes('LYTE')){
                                closeestLyteElem.ltProp('ariaAttributes', {'aria-label':elemLabel});
                            }
                        }
                        /* add focusable and nonfocusable for focusable elements */
                        for(var l=0;l<addFocusCellsLen;l++){
                            if($L(addFocusCells[l]).css('display') !== 'none' && addFocusCells[k] !== addFocusCells[l]){
                                var checkElem = addFocusCells[l];
                                while(!(checkElem.tagName.includes('TH') || checkElem.tagName.includes('TD') || checkElem.tagName.includes('TR') || checkElem.tagName === 'BODY')){
                                    if(checkElem === addFocusCells[k]){
                                        $L(addFocusCells[k]).removeAttr('focusable');
                                        $L(addFocusCells[k]).attr('nonFocusable', 'true');
                                        break;
                                    }
                                    checkElem = checkElem.parentElement;
                                }
                            }
                        }
                        if(!($L(addFocusCells[k]).attr('nonFocusable'))){
                            var actCell = addFocusCells[k];
                            if(!actCell.querySelector('[tabindex][aria-label]:not([focusable], .cxSkipFocusable)')){
                                if(actCell.attributes && !actCell.attributes.focusable && actCell.tabIndex !== -1){
                                    $L(actCell).attr('focusable', 'true');
                                }
                                if((actCell.tagName.includes('TD') || actCell.tagName.includes('TH')) && actCell.tagName === -1){
                                    actCell.tabIndex = 0;
                                }
                                if(elemLabel){
                                    tableCell.ariaLabel = (elemLabel.replace(/ +/g, " ")).trim();
                                }
                                if(!actCell.ariaLabel){
                                    if(actCell.className.includes('cxTableSortIcon')){
                                        actCell.ariaLabel = 'Column Options';
                                    }else if(actCell.textContent && actCell.textContent.trim()!==''){
                                        actCell.ariaLabel = (actCell.textContent.replace(/ +/g, " ")).trim();
                                    }else if(!(actCell.tagName.includes('TH') || actCell.tagName.includes('TD'))){
                                        var cellElement = actCell;
                                        while(!(cellElement.tagName.includes('TH') || cellElement.tagName.includes('TD'))){
                                            cellElement = cellElement.parentElement;
                                            if(cellElement.tagName.includes('TR') || cellElement.tagName.includes('BODY') || cellElement.tagName.includes('TABLE')){
                                                break;
                                            }
                                        }
                                        /* remove text or link in elemLabel for elements inside cell */
                                        if((cellElement.tagName.includes('TH') || cellElement.tagName.includes('TD')) && cellElement.ariaLabel && !actCell.ariaLabel){
                                            var elementLabel = cellElement.ariaLabel;
                                            if(elementLabel.includes('text')){
                                                elementLabel= elementLabel.replace('text','');
                                            }else if(elementLabel.includes('link')){
                                                elementLabel = elementLabel.replace('link','');
                                            }
                                        }
                                    }
                                }
                            }
                            if(actCell.attributes && actCell.attributes.focusable && actCell.querySelector('[focusable]:not(.cxSkipFocusable)')){
                                $L(actCell).removeAttr('[focusable]');
                                actCell.querySelectorAll('[focusable]:not(.cxSkipFocusable)').forEach(ele => {
                                    if(ele.querySelector('[focusable]:not(.cxSkipFocusable)')){
                                        $L(ele).removeAttr('[focusable]');
                                    }
                                })
                            }
                        }
                    }
                    var tableCellTrim = tableCell.textContent.trim();
                    var tableCellTrimLen;
                    if(tableCellTrim){
                        tableCellTrimLen = tableCellTrim.length;
                    }
                    if(((!tableCell.querySelector('[tabIndex = "0"]')) && tableCellTrimLen && tableCellTrimLen>0) || elemLabel){
                        if((tableCell.tagName.includes('TD') || tableCell.tagName.includes('TH')) && tableCell.tagName === -1){
                            tableCell.tabIndex = 0;
                        }
                        if(elemLabel){
                            tableCell.ariaLabel = (elemLabel.replace(/ +/g, " ")).trim();
                        }
                    }
                    var tableCellFocusable = tableCell.querySelectorAll('[focusable]:not(.cxSkipFocusable)');
                    var tableCellFocusableLen;
                    if(tableCellFocusable){
                        tableCellFocusableLen = tableCellFocusable.length;
                    }
                    if((tableCellFocusableLen && tableCellFocusableLen<1 && (!table.attributes.listener || !$L(tableCell).attr('nonFocusable')))){
                        if($L(table).attr('cxIsEditOpen') && $L(tableCell).attr('focusable')){
                            $L(tableCell).removeAttr('focusable');
                        }else if(!$L(table).attr('cxIsEditOpen')){
                            $L(tableCell).attr('focusable', 'true');
                        }
                    }
                }
            // }
            if(!$L(tableCell).attr('cxAriaTableFocus')){
                tableCell.addEventListener('focusin', mixinFuncCall.onFocus.bind(table));
                tableCell.addEventListener('focusout', mixinFuncCall.onFocusOut.bind(table));
                $L(tableCell).attr('cxAriaTableFocus', true);
            }
        }
    }
});


/*
cxAriaTableKeyEvent - attribute - if keyEvent eventlistener added for "No Result Found" Div
cxAriaTableFocus - attribute - if focusin and focusout eventlistener added for all focusable elements 
cxAriaTableKeydown - attribute - if keyEvent eventlistener added for table
cxAriaTableFocusableParent - attribute - if table mixin binded for parent component
*/
